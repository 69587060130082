import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

export default function SearchFilter2({ name = 'search', pageKey = 'page', setSearchParams, params = {}, onSearchSelect, ...attributes }) {
    const [search, setSearch] = useState(params?.[name] || '')
    const inputRef = useRef(null)

    const onChange = useCallback(
        ({ target: { value } }) => {
            setSearch(value)
            setSearchParams((prev) => ({ ...prev, [name]: value, [pageKey]: undefined }))
        },
        [setSearchParams, name, pageKey],
    )

    const onKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter' && search.trim()) {
                onSearchSelect(search)
                setSearch('')
                inputRef.current?.focus()
            }
        },
        [search, onSearchSelect],
    )

    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    return (
        <div style={{ width: '100%' }} className="control has-icons-left is-expanded">
            <input
                onKeyDown={onKeyDown} // Listen for Enter key
                ref={inputRef}
                type="search"
                value={search}
                onChange={onChange}
                className={cn('input')}
                placeholder="Введите штрих-код..."
                {...attributes}
            />
            <span className="icon is-small is-left">
                <ion-icon name="search" />
            </span>
        </div>
    )
}
