import React, { forwardRef } from 'react'
import { domain } from '../urls'

const ComponentToPrint = forwardRef(({ item }, ref) => (
    <div ref={ref}>
        <div style={{
            width: 283, // Adjusted for 75mm width
            height: 148, // Rounded from 147.84 for 39mm height
            padding: 10,
            marginLeft: 8, // Rounded from 7.58 for 2mm gap
            marginRight: 8, // Rounded from 7.58 for 2mm gap
            paddingBottom: 10,
        }}>
            <p className="is-uppercase" style={{ textAlign: 'center' }}>
                <b>
                    <span className="mr-2"><b>{item.name}</b></span>
                </b>
            </p>
            <img
                style={{
                    width: '100%',
                    height: '87%',
                }}
                src={domain + item.barcode}
                alt="Barcode for item"
            />
        </div>
    </div>
))

export default ComponentToPrint
