import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { currency } from '../utils/types'
import { domain } from '../urls'

export default function BorrowForm({ onCancel, onSubmit, initialValues, loading, borrow, clientId }) {
    const [file, setFile] = useState(null)

    async function onSuccess(data, actions) {
        const newData = new FormData()
        if (file) {
            newData.append('image', file, file.name)
        }
        console.log(clientId)

        newData.append('amount', borrow ? data.amount : -data.amount)
        newData.append('client', clientId)
        newData.append('currency', data.currency)
        newData.append('description', data.description)
        newData.append('amount', data.amount)
        newData.append('termDate', data.termDate)
        onSubmit(newData, actions)
    }
    return (
        <Formik onSubmit={onSuccess} initialValues={{
            currency: 'sum',
            description: '',
            amount: '',
            termDate: '',
            ...initialValues,
        }}>
            {({ setFieldValue }) => (

                <Form>
                    <Input
                        name="amount"
                        type="number" label="Цена" validate={required} />
                    <Select optionValue="value" optionLabel="name" empty options={currency} name="currency"
                        label="Выберите тип оплаты" />
                    <Input name="description" component="textarea" label="Описание" />

                    <div className="columns">
                        <div className="column">
                            <p className="level-left">Загрузить изображение</p>
                            <input
                                onChange={(e) => {
                                    setFieldValue('image', e.target.value)
                                    setFile(e.target.files[0])
                                }}
                                disabled={loading}
                                name="image"
                                type="file"
                            />
                            {initialValues && initialValues.image ? (domain + initialValues.image).split('/').pop() : 'Файл не загружен'}
                        </div>
                    </div>
                    <div className="is-pulled-right">
                        <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger" />
                    &nbsp;&nbsp;
                        <Button loading={loading} text="Сохранить" icon="add-outline" type="submit"
                            className="is-success" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
