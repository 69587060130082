import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { currency, measurements } from '../utils/types'
import { useLoad } from '../hooks/request'
import { BRAND_LIST, CAR_LIST, CATEGORY_LIST, MANUFACTURER_LIST, PRODUCT_LOCATION_LIST } from '../urls'
import MultiSelect from './common/MultiSelect'

export default function ProductForm({ onCancel, onSubmit, loading, initialValues }) {
    const cars = useLoad({ url: CAR_LIST, params: { size: 1000 } })
    const categories = useLoad({ url: CATEGORY_LIST, params: { size: 1000 } })
    const brands = useLoad({ url: BRAND_LIST, params: { size: 1000 } })
    const productLocations = useLoad({ url: PRODUCT_LOCATION_LIST, params: { size: 1000 } })
    const producers = useLoad({ url: MANUFACTURER_LIST, params: { size: 1000 } })
    const handleSubmit = (vals, actions) => {
        const data = new FormData()

        // Append image file if it exists
        if (vals.image) {
            data.append('image', vals.image, vals.image.name)
        }

        // Append other fields
        data.append('name', vals.name || '')
        data.append('measurement', vals.measurement || '')
        data.append('paymentType', vals.paymentType || '')
        data.append('barcode', vals.barcode || '')
        data.append('category', vals.category || '')
        data.append('productLocation', vals.productLocation || '')
        data.append('brand', vals.brand || '')
        data.append('manufacturer', vals.manufacturer || '')
        data.append('productCount', vals.productCount || '')
        data.append('article', vals.article || '')

        // Append car IDs properly
        if (vals.car) {
            vals.car.forEach((car) => {
                data.append('car', car.id)
            })
        }

        onSubmit(data, actions)
    }

    return (
        <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={{
                name: '',
                brand: JSON.parse(localStorage.getItem('formData'))?.brand || '',
                measurement: 'pieces',
                paymentType: 'usd',
                barcode: '',
                category: '',
                productLocation: '',
                manufacturer: '',
                car: [],
                article: '',
                productCount: '',
                ...initialValues,
                image: null,
            }}
        >
            {({ setFieldValue }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            <Input name="name" label="Наименование товара" validate={required} disabled={loading} />

                        </div>
                        <div className="column">
                            <Select name="measurement" label="Измерение" optionValue="value" optionLabel="name"
                                options={measurements} disabled={loading} />
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <Input name="barcode" label="Артикул" disabled={loading} />
                        </div>
                        <div className="column">
                            <Select empty name="manufacturer"
                                options={producers.response ? producers.response.results : []}
                                label="Выберите производителя" loading={producers.loading} />
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <MultiSelect
                                name="car"
                                label="Выберите имя автомобиля"
                                placeholder="Выберите имя автомобиля"
                                options={cars.response ? cars.response.results : []}
                                optionLabel="name"
                                optionValue="id"
                            />
                        </div>

                        <div className="column">
                            <Select
                                onChange={(e) => {
                                    const selectedSupplier = e.target.value
                                    setFieldValue('brand', selectedSupplier)
                                    const formData = JSON.parse(localStorage.getItem('formData')) || {}
                                    formData.brand = selectedSupplier
                                    localStorage.setItem('formData', JSON.stringify(formData))
                                }}
                                validate={required}
                                empty
                                options={brands.response ? brands.response.results : []}
                                name="brand"
                                label="Выберите бренд*"
                                loading={brands.loading}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <Select optionValue="value" optionLabel="name" name="paymentType" label="Валюта"
                                options={currency} disabled={loading} />
                        </div>

                        <div className="column">
                            <Input name="article" label="Штрих-код" disabled={loading} />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <Select empty name="category"
                                options={categories.response ? categories.response.results : []}
                                label="Выберите категорию" loading={categories.loading} />
                        </div>
                        <div className="column">
                            <Select
                                empty
                                name="productLocation"
                                options={productLocations.response ? productLocations.response.results : []}
                                label="Выберите местоположение продукта"
                                loading={productLocations.loading}
                            />
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <p className="level-left">Загрузить изображение</p>
                            <input
                                onChange={(e) => {
                                    const file = e.target.files[0]
                                    if (file) {
                                        setFieldValue('image', file)
                                    }
                                }}
                                disabled={loading}
                                name="image"
                                type="file"
                            />
                            <p>
                                {initialValues && initialValues.image
                                    ? decodeURIComponent(initialValues.image.split('/').pop())
                                    : 'Файл не загружен'}
                            </p>
                        </div>
                    </div>

                    <div className="is-pulled-right">
                        <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger"
                            disabled={loading} />
                        &nbsp;&nbsp;
                        <Button loading={loading} text="Сохранить" icon="add-outline" type="submit"
                            className="is-success" disabled={loading} />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
